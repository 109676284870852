import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledEqImage = styled.div`
  position: relative;
  margin: 0 0 20px;
`

const StyledEqImageHolder = styled.figure`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;
`

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

const EqImage = ({ image, alt }) => (
  <StyledEqImage>
    <StyledEqImageHolder>
      <Image src={image} alt={alt} />
    </StyledEqImageHolder>
  </StyledEqImage>
)

EqImage.propTypes = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string,
}

export default EqImage
