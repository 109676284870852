import React, { Component } from 'react'
// import styled from 'styled-components';

import SEO from '../components/SEO/SEO'
import Layout from '../Layout/Layout'
import Hero from '../components/Hero/Hero'
import Header from '../components/Header/Header'
import Subheader from '../components/Subheader/Subheader'
import Paragraph from '../components/Paragraph/Paragraph'
import Container from '../components/Container/Container'
import EqImage from '../components/EqImage/EqImage'

import Image5 from '../assets/images/pomoc-drogowa-holowanie.jpeg'

import sliderImage1 from '../assets/images/sprzet/socage-da20.jpg'
// import sliderImage2 from '../assets/images/sprzet/bizzocchi-autel-195cl.jpg'
// import sliderImage3 from '../assets/images/sprzet/podnosnik-30m.jpg'
import sliderImage4 from '../assets/images/sprzet/haulotte-compact-12.jpg'
import sliderImage5 from '../assets/images/sprzet/grove-amz51e.jpg'
import sliderImage6 from '../assets/images/sprzet/socage-da22.jpg'
import sliderImage7 from '../assets/images/sprzet/socage-da328.jpg'
import sliderImage8 from '../assets/images/sprzet/scania.jpg'

class SprzetPage extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Sprzęt | Poznaj parametry pojazdów i maszyn, które oferujemy"
          keywords={['sprzęt']}
        />
        <Hero image={Image5}>
          <Header>Sprzęt</Header>
          <Subheader>Poznaj parametry naszych urządzeń</Subheader>
        </Hero>
        <Container columns={2}>
          <Paragraph as="article">
            <Header>Podnośnik&nbsp;koszowy Socage&nbsp;DA-328</Header>
            <EqImage image={sliderImage7} alt="Socage DA-328" />
            <ul>
              <li>Wysokość robocza 28m</li>
              <li>Wysięg boczny 16m</li>
              <li>Udźwig kosza 225kg</li>
              <li>Wysokość urządzenia w pozycji transportowej 3,20m</li>
              <li>Długość w pozycji transportowej 6,90m</li>
              <li>Obrót kolumny ciągły</li>
              <li>Dopuszczalna masa całkowita 5,6t</li>
            </ul>
          </Paragraph>
          <Paragraph as="article">
            <Header>Podnośnik&nbsp;koszowy Socage&nbsp;DA-20</Header>
            <EqImage image={sliderImage1} alt="Socage DA-20" />
            <ul>
              <li>Sterowanie w pełni hydrauliczne</li>
              <li>Wysokość robocza 20 m</li>
              <li>Boczny zasięg 8,5 m ( przy obciążeniu 200kg )</li>
              <li>Udźwig kosza 200 kg</li>
              <li>Sterowanie z kosza</li>
              <li>Sterowanie z dołu samochodu</li>
              <li>Kosz obrotowy otwarty</li>
              <li>Gaszenie ,zapalanie silnika z kosza oraz z wieżyczki</li>
              <li>Gniazdo 230V</li>
            </ul>
          </Paragraph>
          {/* <Paragraph as="article">
            <Header>Bizzocchi Autel 195CL</Header>
            <EqImage image={sliderImage2} alt="Bizzocchi Autel 195CL" />
            <ul>
              <li>Wysokość robocza 19,7 m</li>
              <li>Boczny zasięg 8 m ( przy obciążeniu 200kg )</li>
              <li>Udźwig kosza 200 kg</li>
              <li>Sterowanie z kosza</li>
              <li>Sterowanie z dołu samochodu</li>
              <li>Kosz obrotowy otwarty</li>
              <li>Gaszenie ,zapalanie silnika z kosza oraz z wieżyczki</li>
              <li>Gniazdo 230V</li>
              <li>
                Pompa hydrauliczna 230v do pracy w pomieszczeniach
                zamkniętych
              </li>
            </ul>
          </Paragraph> */}
          {/* <Paragraph as="article">
            <Header>Podnośnik koszowy 30 metrów</Header>
            <EqImage
              image={sliderImage3}
              alt="Podnośnik koszowy 30 metrów"
            />
            <ul>
              <li>Wysokość robocza 30 m</li>
              <li>Boczny zasięg 15 m ( przy obciążeniu 200kg )</li>
              <li>Udźwig kosza 200 kg</li>
            </ul>
          </Paragraph> */}
          <Paragraph as="article">
            <Header>Podnośnik&nbsp;koszowy Socage&nbsp;DA-22</Header>
            <EqImage
              image={sliderImage6}
              alt="Podnośnik koszowy 22m Socage DA22"
            />
            <ul>
              <li>Wysokość robocza 22m</li>
              <li>Boczny zasięg 11m (przy obciążeniu 80kg)</li>
              <li>Udźwig kosza 200 kg</li>
              <li>Sterowanie z kosza</li>
              <li>Sterowanie z dołu samochodu</li>
              <li>Obrót wieżyczki 700 º</li>
              <li>Kosz obrotowy otwarty</li>
              <li>Gaszenie ,zapalanie silnika z kosza oraz z wieżyczki</li>
            </ul>
          </Paragraph>
          <Paragraph as="article">
            <Header>
              Elektryczny podnośnik nożycowy Haulotte Compact 12
            </Header>
            <EqImage
              image={sliderImage4}
              alt="Elektryczny podnośnik nożycowy Haulotte Compact 12"
            />
            <ul>
              <li>Wysokość robocza 11.98 m</li>
              <li>Wysokość platformy 9.98 m</li>
              <li>Długość platformy 2.31 m</li>
              <li>Długość platformy - po rozłożeniu 3.23 m</li>
              <li>Długość wydłużeń 0.92 m</li>
            </ul>
          </Paragraph>
          <Paragraph as="article">
            <Header>Podnośnik przegubowo-teleskopowy Grove AMZ51E</Header>
            <EqImage
              image={sliderImage5}
              alt="Podnośnik przegubowo-teleskopowy Grove AMZ51E"
            />
            <ul>
              <li>Wysokość robocza 15,70 metra</li>
              <li>Maksymalny zasięg 7,8 m</li>
              <li>Udźwig 230kg</li>
              <li>Wymiary D6.05m x S1,75m x W2.01m</li>
            </ul>
          </Paragraph>
          <Paragraph as="article">
            <Header>Laweta Scania</Header>
            <EqImage image={sliderImage8} alt="Laweta Scania" />
            <ul>
              <li>Ładowność 7 ton</li>
            </ul>
          </Paragraph>
        </Container>
      </Layout>
    )
  }
}

export default SprzetPage
